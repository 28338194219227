@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

// Our variables
$base-font-family: 'Montserrat', sans-serif;
$base-font-size:	1.8rem;
$base-font-weight:	400;

$heading-font: Georgia, "Times New Roman", Times, serif;

$green: lighten(#003232, 4%);
$cream: lighten(#FFE9BB, 5%);

$base-line-height:	1.5;
$base-text-color:	#555;
$divide-background: #000;
$spot-color: $green;
$muted-color: #e2e2e2;
$background-color: $cream;

$header-height: 6.4rem;
$content-width: 138rem;

$active: $green;

@mixin small-cap {
	font-size: 1.3rem;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

html,
body {
	padding: 0;
	margin: 0;
	position: relative;
  	height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	font-size: 1.6rem;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;

	@media only screen and (min-width:500px) {
		font-size: $base-font-size;
	}

	&.navOpen {

		#global-wrapper {
			filter: blur(4px);
		}

		.mobile-nav {
			top: 0;
			background: transparentize($background-color, 0.2);
		}

		@media only screen and (min-width:870px) {
			#global-wrapper {
				filter: none;
			}

			.mobile-nav {
				display: none;
			}
		}

	}

}

a {
	color: $active;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
	color: #333;
	font-weight: normal;
	line-height: 1em;
	padding-bottom: 0.35em;
	font-family: $heading-font;

	& + p {
		margin-top: 0;
	}

}

.banner {
	width: 100%;
	background: $active;
	color: white;
	text-align: center;
	padding: 2.4rem 1.6rem;
	line-height: 1.6rem;
	font-size: 1.4rem;

	a {
		color: $background-color;
		padding-bottom: 0.4rem;
		border-bottom: 2px solid $background-color;
	}
}

.cta {
	margin-top: 2.4rem;
}

.section {
	padding: 9.6rem  0;

	// @media only screen and (min-width:580px) {
	// 	padding: 12.8rem 0;
	// }

	&--flush {
		padding: 0;
	}

	h1 {
		padding: 0;
		margin-bottom: 1.6rem;
	}

	&-hero {
		position: relative;
		background-color: $active;
		max-width: 210rem;
		margin: 0 auto;
		padding: 0.8rem;
		padding-top: calc(100px + 0.8rem);

		@media only screen and (min-width:580px) {
			padding: 3.2rem;
			padding-top: calc(121px + 3.2rem);
		}

		.content-wrapper {
			position: relative;
			text-align: center;
			display: flex;
			background: transparent;
			border: 1px solid $background-color;
			z-index: 20;
			max-width: 100%;
			color: $background-color;
			padding: 3.2rem 1.6rem;
			min-height: 50vh;

			@media only screen and (min-width:580px) {
				padding: 6.4rem 1.6rem;
				min-height: 73vh;
			}

			.content {
				margin: auto;
				color: $background-color;
			}

			h1 {
				color: white;
			}

			p {
				color: white;
				width: 100%;
				max-width: 80rem;
				margin-left: auto;
				margin-right: auto;
			}

		}

	}

}

.hero-image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: transparent url(#) no-repeat center center;
	background-size: cover;
	z-index: 10;
	opacity: 0.3;
}

#sleepy-hollow-hero {

	.hero-image {
		background-position: center bottom;
	}

}

.full-image {
	width: 100%;
	display: block;
}

.image {

	&--border {
		border: 1.6rem solid $background-color;
		outline: 0.1rem solid $active;
	}

}

.map-border {
	border: 1.6rem solid $background-color;
	outline: 0.1rem solid $active;
}

.heading-icon {
	position: relative;
	padding-left: 5.2rem;

	&__icon {
		position: absolute;
		top: -9px;
		left: 0;
		width: 42px;
		height: 42px;
		background: $active;
		border-radius: 50%;
		display: flex;

		svg {
			width: 24px;
			height: 24px;
			margin: auto;
		}

		path {
			fill: $background-color;
		}
	}

}

.blocks {
	display: flex;
	flex-wrap: wrap;
	margin-top: 6.4rem;

	.block {
		padding: 1.6rem 0;

		&--center {
			text-align: center;
		}

		h2 {
			font-size: 2.4rem;

			svg {
				width: 24px;
				height: 24px;
				display: inline-block;
				vertical-align: bottom;
			}
		}

		img {
			width: 100%;
			display: block;
			margin: 0.8em 0;

			& + h2 {
				margin-top: 1.6rem;
			}

		}

		&--headshot {

			img {
				margin: auto;
				border-radius: 50%;
				width: 100%;
				max-width: 20rem;
				border: 2px solid $active;
			}

			p {
				@include small-cap;
			}

		}

		&--property {
			display: block;
			padding: 0;
			position: relative;
			background-color: $active;
			margin: 0 0 1px 0 !important;

			@media only screen and (min-width:745px) {
				margin: 0 1px 0 0 !important;
				width: calc((100% - 2px) / 3) !important;

				&:last-of-type{
					margin-right: 0 !important;
				}
			}

			

			.property-image {
				display: block;
				background-size: cover;
				opacity: 1;
				transition: opacity, 0.3s;
				margin: 0;
			}

			&-available {
				overflow: hidden;

				&:before {
					text-indent: 1rem;
					content:'Suite Available';
					position: absolute;
					width: 25rem;
					top: 4.5rem;
					right: -5.6rem;
					height: 4.8rem;
					background: $active;
					color: white;
					text-align: center;
					line-height: 4.8rem;
					font-size: 1.4rem;
					transform: rotate(45deg);
					z-index: 10;
				}

			}

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				height: 8.1rem;
				background: transparentize($active, 0.2);
			}

			.button {
				position: absolute;
				bottom: 1.6rem;
				right: 1.6rem;
				z-index: 10;
				margin: 0;
			}
		}

	}

	&--three {

		.block {
			width: 100%;

			@media only screen and (min-width:645px) and (max-width: 919px) {
			width: calc((100% - 32px) / 2);

				&:nth-child(2n+2){
					margin: 0 0 0 32px;
				}

			}

			@media only screen and (min-width:920px) {
				width: calc((100% - 64px) / 3);

				&:nth-child(2), &:nth-child(2n+5){
					margin: 0 3.2rem;
				}
			}

		}

	}

	&--four {

		.block {
			width: calc((100% - 96px) / 4);

			&:nth-child(2) {
				margin: 0 3.2rem;
			}

			&:nth-child(3){
				margin-right: 3.2rem;
			}

		}

	}

	&--five {
		justify-content: center;

		.block {
			margin: 0 1.6rem;
			width: calc((100% - 32px) / 2);
			padding-bottom: 0;

			@media only screen and (max-width:519px) {
				&:nth-child(odd) {
					margin-left: 0;
				}

				&:nth-child(even){
					margin-right: 0;
				}
			}

			@media only screen and (min-width:520px) {
				width: calc((100% - 64px) / 3);
				padding-bottom: 1.6rem;
			}

			@media only screen and (min-width:520px) and (max-width:849px) {

				&:nth-child(1) {
					margin-left: 0;
				}

				&:nth-child(3){
					margin-right: 0;
				}
			}

			@media only screen and (min-width:850px) {
				width: calc((100% - 128px) / 5);

				&:nth-child(1) {
					margin-left: 0;
				}

				&:nth-child(1n+5){
					margin-right: 0;
				}
			}

		}

	}
}

.map-border--home {
	position: relative;

	.button {
		position: absolute;
		bottom: 3.2rem;
		left: 50%;
		margin-left: -10rem;
		z-index: 10;
		margin-right: 0;
	}
}

.map-home {
	width:100%;
	height:500px;

	@media only screen and (min-width:500px) {
		height:700px;
	}
}

.mapToggleButton {
	&--in {
		.zoom-in {
			display: inline;
		}
		.zoom-out {
			display: none;
		}
	}
	&--out {
		.zoom-out {
			display: inline;
		}
		.zoom-in {
			display: none;
		}
	}
}

.map-info {

	@media only screen and (min-width:1030px) {
		display: flex;
	}

	#map {
		width: 100%;
		margin: 0 auto 4.8rem auto;
		height: 500px;

		@media only screen and (min-width:500px) {
			height: 630px;
		}

		@media only screen and (min-width:1030px) {
			width: 66.6666%;
			min-width: 700px;
			height: 700px;
			margin: 0;
		}
	}

	.transport-blocks {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		@media only screen and (min-width:1030px) {
			display: block;
			margin: 2.4rem 0 0 2.4rem;
			width: auto;
		}
	}

	.transport-block {
		margin-bottom: 4.8rem;
		width: 100%;
		
		&:last-of-type {
			width: 100%;
		}

		@media only screen and (min-width:500px) {
			width: 50%;
		}

		@media only screen and (min-width:800px) and (max-width:1029px) {
			width: 33.3333%;
			padding-right: 0.8rem;
			&:last-of-type {
				width: 33.3333%;
			}
		}

		@media only screen and (min-width:1030px) {
			width: auto;
			padding-right: 0;
			&:last-of-type {
				width: auto;
			}
		}
		
	}
}

.distance-list {
	list-style-type: none;
	margin-left: 5.2rem;

	li {
		padding: 0.4rem 0;
	}

	&--icons {
		list-style-type: none;
		margin-left: 0;

		svg {
			width: 24px;
			height: 24px;
			vertical-align: bottom;
		}
	}

	.distance {
		font-size: 1.4rem;
		display: inline-block;
	}
}

.cta {
	width: 100%;
}

.button {
	@include small-cap;
	display: inline-block;
	color: $active;
	padding: 1.4rem 1.6rem;
	margin-right: 0.8rem;
	min-width: 20.5rem;
	text-align: center;
	border: 0;
	border: 1px solid $active;
	transition: all 0.3s;
	margin-top: 0.4rem;
	cursor: pointer;

	&:hover {
		background-color: $active;
		color: white;
	}

	&--alt {
		border-color: $background-color;
		color: $background-color;
	}

	&--alt-white {
		border-color: white;
		color: white;
	}

	&--alt, &--alt-white {

		&:hover {
			background-color: $background-color;
			color: $active;
		}
	}

	&--action-fill {
		background-color: $active;
		color: white;
	}

	&--action-fill-alt {
		background-color: $background-color;
		color: $active;
	}
}

.google-map {
	display: block;
	width: 100%;
}

.content-wrapper {
	width: 100%;
	max-width: 143.2rem;
	margin: 0 auto;
	padding: 0 1.6rem;

	&.header {
		position: absolute;
		max-width: 100%;
		// top: 6.4rem;
		left: 0;
		right: 0;
		z-index: 50;
		background: transparentize($color: $background-color, $amount: 0.125);
		padding: 0;

		@media only screen and (min-width:580px) {
			padding: 0 1.6rem;
		}

		@media only screen and (min-width:2120px) {
			background: $background-color;
		}

	}

}

#home-properties {
	max-width: 210rem;
	margin-left: auto;
	margin-right: auto;
}

.header-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 210rem;
	margin: 0 auto;
	width: 100%;
	padding: 1.6rem;
	padding-right: 7.2rem;
	height: 10rem;

	@media only screen and (min-width:580px) {
		height: 12rem;
	}

	@media only screen and (min-width:870px) {
		padding-right: 1.6rem;
	}

	.button {
		margin: 0;
	}
}

.global-header {

	img {
		display: block;
		width: 100%;
		max-width: 12rem;

		@media only screen and (min-width:580px) {
			max-width: 20rem;
		}
	}

}

.mobile-menu-trigger {
	position: absolute;
	right: 1.6rem;
	width: 49px;
	height: 49px;
	border: 1px solid $active;
	display: flex;

	@media only screen and (min-width:580px) {
		right: 3.2rem;
	}
	
	svg {
		margin: auto;
		width: 24px;
		height: 24px;

		path {
			fill: $active;
		}
	}

	@media only screen and (min-width:870px) {
		display: none;
	}

}

.mobile-nav {
	display: flex;
	position: fixed;
	top: -100%;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	min-height: 100%;
	z-index: 100;
	background: transparentize($background-color, 1);
	text-align: center;
	transition: background 0.3s;

	ul {
		margin: auto;
		padding: 0;
		@include small-cap;

		a:not(.button) {
			display: inline-block;
			padding: 0.8rem 0;
			margin: 0.4rem 0;
		}

		.button {
			margin: 1.6rem 0;
		}
	}

	.closer {
		svg {
			width: 24px;
			height: 24px;
			display: inline-block;
			vertical-align: bottom;
			margin-bottom: -0.2rem;

			path {
				fill: $active;
			}
		}
	}
}

.global-nav {
	text-align: center;
	display: none;

	@media only screen and (min-width:870px) {
		display: block;
	}

	ul {
		display: flex;
		margin: 0 1.6rem;
	}

	li {
		@include small-cap;
		padding: 0.8rem 1.6rem;

		a {
			padding: 0.8rem 0;
		}
	}

	.active {
		cursor: default;
		border-bottom: 2px solid $active;
	}

}

#nav-home {
	display: none;
	@media only screen and (min-width:1160px) {
		display: block;
	}
}

#nav-get-in-touch {
	display: none;
	@media only screen and (min-width:580px) {
		display: inline-block;
	}
}

.property-location-features {

	@media only screen and (min-width:990px) {
		display: flex;
		flex-wrap: nowrap;
	}
}

.property-location, .property-features {
	max-width: 70rem;
	margin: 0 auto;

	@media only screen and (min-width:990px) {
		width: 50%;
		margin: 0;
	}
}

.property-location {
	@media only screen and (min-width:990px) {
		padding-right: 1.6rem;
	}
	img {
		margin: 0;
		width: 100%;
	}
}

.property-features {
	padding-top: 3.2rem;

	@media only screen and (min-width:990px) {
		padding-top: 0;
		padding-left: 1.6rem;
	}

	ul {
		list-style-type: disc;
		margin-left: 2.4rem;
	}
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	margin-top: 6.4rem;
	border-left: 1px solid $background-color;

	&-item {
		display: block;
		border: 1px solid $background-color;
		border-top: 0;
		border-left: 0;
		width: calc(100% / 2);
		
		@media only screen and (min-width:600px) and (max-width: 919px) {
			width: calc(100% / 3);
			&:last-of-type {
				display: none;
			}
		}

		@media only screen and (min-width:920px) {
			width: calc(100% / 5);
		}

		img {
			width: 100%;
			display: block;
		}
	}
}

.suites {
	margin-top: 6.4rem;
	display: flex;
	flex-wrap: wrap;

	&--single {
		.suite {
			width: 100%;
		}
	}
}

.suite {
	display: flex;
	margin-bottom: 1px;
	width: 100%;

	@media only screen and (min-width:1020px) {
		width: calc((100% - 1px) / 2);
	}

	p, .sq-ft {
		font-size: 1.6rem;
	}

	.sq-ft {
		display: inline-block;
	}

	.button {
		min-width: 13.5rem;
	}

	.button:last-of-type {
		margin-right: 0;
	}

	@media only screen and (min-width:540px) {
		.button {
			min-width: 20.5rem;
		}
	}

	&:nth-child(odd) {
		margin-right: 1px;
	}

	&__status-img {
		display: flex;
		background: $active;
		width: 150px;
		min-width: 150px;
		height: 150px;

		@media only screen and (min-width:500px) {
			width: 200px;
			min-width: 200px;
			height: 200px;
		}

		img {
			display: block;
			width: 70%;
			margin: auto;
			filter: saturate(0) brightness(10);
		}

	}

	&__title {
		flex-grow: 1;
		display: flex;

		&--taken {
			color: #999;
			opacity: 0.85;
		}

		h2 {
			font-size: 1.8rem;

			@media only screen and (min-width:500px) {
				font-size: 2.4rem;
			}
		}

		padding: 2.4rem;
		background: rgba(0,0,0,0.05);
	}

	&__content {
		margin: auto 0;
	}

	&--free {
		position: relative;

		.suite__status-img {
			position: relative;
			background: url(#) no-repeat center center;
			background-size: cover;
			overflow: hidden;

			&:before {
				text-indent: 1.2rem;
				content:'Suite Available';
				position: absolute;
				width: 25rem;
				top: 2.8rem;
				left: -8.5rem;
				height: 4.8rem;
				background: $active;
				color: $background-color;
				text-align: center;
				line-height: 4.8rem;
				font-size: 1.4rem;
				transform: rotate(-45deg);
				z-index: 10;
			}

			img {
				width: 100%;
				filter: none;
			}

		}

		.suite__title {
			@media only screen and (max-width:540px) {
				padding-bottom: 65px;
			}
		}

		h2 {
			margin-bottom: 1rem;
		}

	}

	&__cta {
		@media only screen and (max-width:540px) {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: transparentize($active, 0.8);
			text-align: right;
			padding: 0.8rem;
			padding-top: 0.4rem;
			z-index: 20;

			.button {
				background: $background-color;
			}
		}
	}
}

.global-footer {
	background: $active;
	color: white;
	padding: 6.4rem 0 3.2rem 0;

	@media only screen and (min-width:540px) {
		padding: 12.8rem 0 3.2rem 0;
	}
	

	h1, h2 {
		color: $background-color;
	}

	a {
		color: $background-color;
	}
}

.global-contact-links-address {
	@media only screen and (min-width:740px) {
		display: flex;
	}
}

.global-contact {

	@media only screen and (min-width:740px) and (max-width: 1019px) {
		width: 75%;
		padding-right: 2.4rem;
	}

	@media only screen and (min-width:1020px) {
		width: 66.6666%;
		padding-right: 2.4rem;
	}
}

.global-links-address {
	margin-top: 4rem;
	font-size: 1.4rem;
	text-align: center;

	@media only screen and (min-width:570px) {
		text-align: left;
	}

	@media only screen and (min-width:570px) and (max-width: 739px) {
		display: flex;
		
	}

	@media only screen and (min-width:740px) and (max-width: 1019px) {
		display: block;
	}

	@media only screen and (min-width:740px) {
		margin-top: 8rem;
	}

	@media only screen and (min-width:1020px) {
		width: 33.3333%;
		display: flex;
	}

	h1 {
		font-size: 2.4rem;
	}
}

.global-address {

	@media only screen and (min-width:570px) and (max-width: 739px) {
		width: 50%;
	}

	@media only screen and (min-width:740px) and (max-width: 1019px) {
		width: 100%;
	}

	@media only screen and (min-width:1020px) {
		width: 50%;
		padding-right: 1.6rem;
	}

	svg {
		width: 16px;
		height: 16px;
		display: inline-block;
		vertical-align: text-bottom;
	}
}

.global-links {
	padding-top: 1.6rem;

	@media only screen and (min-width:570px) {
		padding-top: 0;
	}

	@media only screen and (min-width:570px) and (max-width: 739px) {
		padding-left: 1.2rem;
	}

	@media only screen and (min-width:740px) {
		padding-left: 0;
	}
	
	@media only screen and (min-width:1020px) {
		width: 50%;
		padding-left: 1.6rem;
	}

	ul {

		@media only screen and (min-width:570px) {
			list-style-type: disc;
			margin-left: 2.4rem;
		}

		li {
			padding: 0.25rem 0;

			&:first-of-type {
				padding-top: 0;
			}
		}
	}

}

.form {
	width: 100%;
	max-width: 75rem;

	label {
		display: block;
		margin: 0.8rem 0;
		@include small-cap;

		input, textarea {
			margin-top: 0.3rem;
			display: block;
			width: 100%;
			border: 0;
			padding: 1rem;
			font-size: 1.6rem;
		}

		textarea {
			margin-bottom: 2.4rem;
		}

	}

	@media only screen and (min-width:570px) {
		&__row {
			display: flex;
			justify-content: space-between;

			label {
				width: 48%;
			}
		}
	}

	button[type=submit]{
		border-color: $background-color;
		background: transparent;
		color: $background-color;
		cursor: pointer;

		&:hover {
			background-color: $background-color;
			color: $active;
		}

		&[disabled], &[disabled]:hover {
			color: $active;
			background: $active;
			cursor: default;
			position: relative;
			border-color: transparentize($background-color, 0.5);
	
			&:after {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -1.2rem;
				margin-left: -1rem;
				display: block;
				content:'';
				width: 2rem;
				height: 2rem;
				border: 2px solid transparent;
				border-top-color: white;
				border-radius: 50%;
				animation: spin .8s ease infinite;
				z-index: 20;
			}
		}

	}

	&__disclaimer {
		padding-top: 1.6rem;
		font-size: 1.1rem;
		color: lighten(#356C6C, 15%);
	}

}

@keyframes spin {
	from{
	  transform: rotate(0deg);
	}
	to{
	  transform: rotate(360deg);
	}
  }

.credits {
	font-size: 1.3rem;
	padding-top: 2.4rem;
	color: lighten(#356C6C, 15%);
}

// Toaster
.toaster {
	display: block;
	background: white;
	z-index:9999;
	position:fixed;
	top:-100%;
	width:100%;
	padding: 2.4rem 1.6rem;
	color: #000;
	text-align:center;
	opacity: 0;
	transition: all 1s;
	font-size: 1.4rem;

  &.open {
    opacity: 1;
    top: 0;
  }

  &__content {
    display:block;
  }

  svg {
	  display: none;
	  width: 24px;
	  height: 24px;
	  margin: 0 auto;
  }

  &.failed {
	
	svg {

		&.failed {
			display: block;

			path {
				fill: #ff6347;
			}

		}
	}
  }

  &.success {

	svg {
	
		&.success {
			display: block;
		}
		path {
			fill: $active;
		}
	}
  }

}